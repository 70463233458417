import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout-offline-application"
import Seo from "../components/seo"

//components

import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import Hero from "../components/hero/hero-transportation-and-logistics"

const Cases = loadable(() => pMinDelay(import("../components/cases"), 500))
const Modal = loadable(() => import("../components/modal/modal-transportation"))
// const ExitIntentModal = loadable(() =>
//   import("../components/modal/exit-intent-offline-application")
// )
const UnlockEnterprise = loadable(() =>
  pMinDelay(import("../components/unlock-enterprise"), 500)
)
const Testimonials2 = loadable(() =>
  pMinDelay(import("../components/swiper/testimonial-swiper"), 500)
)

class TransportationAndLogisticsPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        banner={<Hero />}
      >
        <Seo
          title="Transportation and Logistics | Quandary Consulting Group"
          description="Using low-code tools, we build systems to modernize port operations, maximizing cargo efficiency and increasing speed to market. (In weeks.)
          "
        />
        <div id="main">
          <LazyLoadComponent>
            <section
              className="quickbase-integrations"
              style={{ padding: "5rem 0" }}
            >
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12 text-center">
                    <h2 className="h3 text-white mt-4">
                      CUSTOM APPLICATIONS FOR UNMATCHED TRANSPORTATION AND
                      LOGISTICS MANAGEMENT
                    </h2>
                    <p className="text-white">
                      Manage terminal operations. Reduce port congestion. And
                      increase container movement.
                    </p>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/proj-mng.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold text-uppercase">
                      Centralized Dashboard
                    </p>
                    <p className="pt-2 text-white">
                      Make executive decisions in real-time with immediate
                      access to real-time reports on productivity, volume,
                      operational reporting, scheduling, and shipping activity.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/terminal-operation.webp"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold text-uppercase">
                      Terminal Operation Reporting
                    </p>
                    <p className="pt-2 text-white">
                      Deliver a thorough overview of vessel activity, cargo
                      handling, detentions, and non-productive activities.
                      Enhances port operations and billing with concise
                      insights.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/labor-fulfillment.webp"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold text-uppercase">
                      Labor Fulfillment and Scheduling
                    </p>
                    <p className="pt-2 text-white">
                      Integrate HR management with your ERP, streamlining
                      personnel management across projects throughout the port
                      (including labor fulfillment from unions).
                    </p>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/maintenance-tracking.webp"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold text-uppercase">
                      Preventative Maintenance Tracking
                    </p>
                    <p className="pt-2 text-white">
                      Automate tracking for every vehicle in your port, log
                      preventative maintenance (oil changes, tire replacements,
                      engine maintenance, etc.), and send notifications to
                      service teams.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/operational-scorecard.webp"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold text-uppercase">
                      Operational Scorecard
                    </p>
                    <p className="pt-2 text-white">
                      Summarize vessel management operations across numerous
                      ships from numerous companies and compile data into an
                      accurate scorecard to evaluate efficiency against
                      historical data.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="what-to-expect" style={{ padding: "5rem 0" }}>
              <div className="container pb-5">
                <div className="row">
                  <div className="col-lg-12 col-md-8 mx-auto text-center">
                    <h2 className="font-weight-medium">
                      WE KNOW TRANSPORTATION AND LOGISTICS.
                    </h2>
                    <p className="mt-3">
                      We build the infrastructure your team needs to manage
                      shipping operations across your entire port. <br />
                      From boat to truck and rail. And back again.
                    </p>
                  </div>
                </div>
                <div className="row" style={{ padding: "65px 0px 0px 0px" }}>
                  <div
                    className="col mb-4 mx-5 what-to-expext-box text-center"
                    style={{ borderRadius: "5px" }}
                  >
                    <p className="text-green pt-3 font-weight-bold">
                      DISCOVERY
                    </p>
                    <p className="my-3 pb-3">
                      We understand your business first. Digging deep to uncover
                      the source of the problem.
                    </p>
                  </div>
                  <div
                    className="col mb-4 mx-5 what-to-expext-box text-center"
                    style={{ borderRadius: "5px" }}
                  >
                    <p className="text-green pt-3 font-weight-bold">
                      DEVELOPMENT
                    </p>
                    <p className="my-3 pb-3">
                      We build high-quality, custom applications quickly using
                      low-code tools in weeks (not 12-18 months).
                    </p>
                  </div>
                  <div
                    className="col mb-4 mx-5 what-to-expext-box text-center"
                    style={{ borderRadius: "5px" }}
                  >
                    <p className="text-green pt-3 font-weight-bold">
                      DEPLOYMENT
                    </p>
                    <p className="my-3 pb-3">
                      We launch the new tools and support your team while
                      sharing opportunities we uncovered for future growth.
                    </p>
                  </div>
                </div>
                <div className="col-lg-8 mx-auto text-center pt-5">
                  <Modal />
                </div>
              </div>
            </section>

            <section id="case-studies-container">
              <div id="case-studies" className="bg-indigo">
                <div className="container-fluid">
                  <div className="intro text-center">
                    <div className="row">
                      <div className="col-md-10 mx-auto">
                        <p
                          className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                          style={{ fontFamily: "Futura,sans-serif" }}
                        >
                          Case Studies
                        </p>
                        <h2 className=" my-2 text-white">
                          We Save Clients 1000s of Hours. Every Year.
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <Cases
                    allSanityCaseStudy={this.props.data.allSanityCaseStudy}
                  />
                </div>
                <div className="text-center">
                  <Link
                    className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
                    to="/case-studies/"
                  >
                    More Case Studies
                  </Link>
                </div>
              </div>
            </section>
            <section id="case-studies-container">
              <div id="case-studies">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8 mx-auto text-center">
                      <h2>
                        Ensure Safety and Security. Achieve Operational
                        Excellence. Improve Customer Experience.
                      </h2>
                      <p>
                        Reduce errors and error handling with custom
                        applications and dashboards that keep your port
                        operations on track so you can move more ships through
                        your port.
                      </p>

                      <div className="relative">
                        <Modal />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Testimonials2 />
            <UnlockEnterprise />
          </LazyLoadComponent>
        </div>
      </Layout>
    )
  }
}

export default TransportationAndLogisticsPage

export const TransportationAndLogisticsPageQuery = graphql`
  query TransportationAndLogisticsPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
